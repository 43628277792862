:root {
  --plyr-color-main: #f5841e;
}

html {
  @apply h-full;
}

@layer components {
  .default-wrapper {
    @apply max-w-8xl px-5 md:px-18 w-full mx-auto;
  }

  .simple-wrapper {
    @apply max-w-8xl px-5 w-full mx-auto;
  }
}

.gslide-inline {
  @apply bg-transparent;
}
.gslide-inline .ginlined-content {
  @apply p-6 shadow-none;
}

.glightbox-clean .gslide-media {
  @apply shadow-none;
}

.goverlay {
  @apply !bg-charcoal-grey !bg-opacity-75;
}

.text-shadow {
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
}

.glightbox-clean .gprev,
.glightbox-clean .gnext {
  @apply top-auto bottom-0 md:bottom-auto md:top-1/2 transform md:-translate-y-1/2 scale-75;
}

.gslide-inline .ginlined-content {
  @apply pb-8 md:pb-6;
}

.glightbox-mobile .glightbox-container .gslide-description {
  flex: initial;
  position: relative;
  bottom: 0;
  padding: 0;
  background: white;
}

.glightbox-mobile .glightbox-container .gslide-title {
  @apply text-dark;
}

.grecaptcha-badge {
  @apply !hidden;
}

.entry-article {
  .banner {
    clip-path: polygon(0 0, 100% 0%, 100% 86%, 50% 100%, 0% 86%);
  }

  .text-format {
    h2 {
      @apply font-extrabold mb-6 sm:mb-12;
    }
  }
}
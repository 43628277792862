.pagination {
  grid-template-columns: auto auto auto;
  .prev-button, .next-button {
    @apply row-start-2;
  }

  .numbers {
    @apply row-start-1 col-span-2;
  }
  @screen sm {
    .prev-button, .next-button, .numbers {
      @apply row-start-1;
    }
  }
}
.text-format {  
  h2 {
    @apply big-title-text text-2xl sm:text-4xl xl:text-[45px] font-light mb-6 sm:mb-12 last:mb-0 mt-12 sm:mt-16 xl:mt-24 first:!mt-0 !leading-[1.1];

    & strong {
      @apply font-bold;
    }
  }

  h3 {
    @apply secondary-title-text text-xl sm:text-2xl xl:text-3xl mb-5 mt-8 sm:mt-12 xl:mt-16 !leading-[1.1];
  }

  h4 {
    @apply tertary-title-text mb-4 mt-6 sm:mt-8 xl:mt-12 !leading-[1.1] text-lg sm:text-xl xl:text-[28px];
  }

  p {
    @apply mb-6;

    &.mb-2 {
      margin-bottom: 0.5rem;
    }
  }

  &>*:last-child {
    @apply mb-0;
  }

  &.small {
    @apply small-body-text;

    strong {
      @apply font-semibold;
    }
  }

  a:not(.button) {
    @apply text-pumpkin-orange;

    background-image: linear-gradient(90deg, currentColor 0%, currentColor 100%);
    background-size: 0% 2px;
    background-position: right bottom;
    background-repeat: no-repeat;
    transition: background-size 300ms ease;

    &:hover {
      background-position: left bottom;
      background-size: 100% 2px;
    }
  }

  a.button {
    @apply bg-no-repeat pr-6 my-6 inline-block;
    background-position: right 0px;
    background-image: url('../../assets/arrow-orange.svg');

    &:hover {
      @apply text-pumpkin-orange;
    }
  }

  ul, ol {
    @apply leading-normal my-6 mx-[1em];

    li {
      &::marker {
        @apply text-pumpkin-orange;
      }
    }
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }
}
.navigation {
  .menu-button {
    @apply w-8 h-6;

    .line {
      @apply w-full absolute right-0 transform bg-white transition-all duration-150;
      height: 3px;
      border-radius: 1.5px;

      &.line-1 {
        @apply top-0;
      }

      &.line-2 {
        @apply top-1/2 -translate-y-1/2;
      }

      &.line-3 {
        @apply top-full -translate-y-full;
      }
    }

    &:hover {
      @media (hover: hover) {
        .line {
          &.line-1 {
            @apply top-1/2 w-1/3 rotate-45;
            --tw-translate-y: -200%;
          }

          &.line-3 {
            @apply top-1/2 translate-y-full w-1/3 -rotate-45;
          }
        }
      }
    }

    &.open {
      .line {
        &.line-1 {
          @apply top-1/2 w-full -translate-y-1/2 rotate-45;
        }

        &.line-2 {
          @apply opacity-0;
        }

        &.line-3 {
          @apply top-1/2 w-full -translate-y-1/2 -rotate-45;
        }
      }
    }
  }

  .menu {
    height: calc(100vh - 72px);

    @screen lg {
      height: 100%;
    }
  }

  .social-line {
    @apply mt-[-3px];
  }

  .menu-item-wrapper {
    &:after {
      content: '';
      @apply hidden lg:block absolute right-0 top-0 w-0 h-[5px] duration-300 bg-pumpkin-orange;
      transition: width 300ms ease; 
    }

    &.active, &:hover {
      &:after {
        @apply left-0 w-full;
      }
      &>.main-menu-item {
        @apply text-pumpkin-orange;

        svg {
          @apply fill-pumpkin-orange;
        }
      }
    }
  }

  .main-menu-item {
    &.open {
      svg {
        @apply rotate-90;
      }
    }
  }
}
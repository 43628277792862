.form-card {
  @apply px-6 md:px-12 pt-6 md:pt-10 w-full;

  .label-text {
    &.required {
      &:after {
        content: "*";
      }
    }
  }

  .form-check-label {
    @apply inline-flex items-center cursor-pointer relative;

    .checkmark {
      @apply flex w-4 h-4 border border-charcoal-grey transition-colors duration-150 bg-white items-center justify-center mr-2;

      svg {
        @apply opacity-0 transition-opacity duration-150;
      }
    }

    input {
      @apply absolute left-0 top-0 h-0 w-0 invisible;
    }

    &:hover {
      & .checkmark {
        @apply bg-pale-grey;
      }
    }

    input:checked ~ .checkmark {
      @apply bg-charcoal-grey;

      & svg {
        @apply opacity-100;
      }
    }
  }

  .submit-button {
    &.ff-loading {
      &:after {
        background-image: url("../../assets/loader.gif");
      }
    }
  }

  .ff-form-errors {
    @apply bg-white border border-tomato-red text-tomato-red mb-8 p-6;
  }

  .ff-form-success {
    @apply bg-white border border-fern-green text-fern-green mb-8 p-6;
  }

  .ff-has-errors {
    @apply border-tomato-red;
  }

  .ff-errors {
    @apply text-tomato-red;
  }

  .required::after {
    content: "*";
    color: #d00;
    margin-left: 5px;
  }
  button[type="submit"].freeform-processing {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  button[type="submit"].freeform-processing:before {
    content: "";
    display: block;
    flex: 1 0 11px;
    width: 11px;
    height: 11px;
    margin-right: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent transparent #fff #fff;
    border-radius: 50%;
    animation: freeform-processing 0.5s linear infinite;
  }
  @keyframes freeform-processing {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(1turn);
    }
  }
  .input-group-one-line > div {
    float: left;
    margin-right: 15px;
  }
}

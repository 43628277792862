.footer {
  .grid {
    @screen sm {
      grid-template-columns: 1fr max-content 1fr;
    }
  }
}

.footer-cta {
  .block-bg {
    clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 100%);
    filter: brightness(0.6);
  }
  .block-content {
    min-height: 24rem;

    @screen md {
      min-height: 21rem;
    }
  }
}
@layer components {
  .banner-title-text {
    @apply font-overpass font-black !leading-none text-4xl sm:text-5xl md:text-[65px];
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.95px;
  }

  .home-banner-text {
    @apply font-overpass font-extrabold !leading-none text-[32px] md:text-[52px];
    font-stretch: normal;
    font-style: normal;
  }

  .big-title-text {
    @apply font-overpass font-bold text-2xl sm:text-4xl xl:text-[45px] !leading-[1.1];
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  .big-title-text-2 {
    @apply font-overpass font-extrabold text-2xl sm:text-3xl md:text-[45px] !leading-[1.1];
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  .big-stat-text {
    @apply font-overpass font-black text-[32px] sm:text-[96px] !leading-[1.1];
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  .stat-text {
    @apply font-overpass font-bold text-[32px] sm:text-4xl !leading-[1.1];
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  .secondary-title-text {
    @apply font-overpass font-bold text-2xl sm:text-3xl !leading-tight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  .tertary-title-text {
    @apply font-overpass font-bold;
    font-size: 28px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
  }
  .card-title-text {
    @apply text-2xl font-overpass font-black !leading-none;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.72px;
  }
  .pre-title-text {
    @apply font-overpass font-black text-[13px] md:text-base !leading-relaxed;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.48px;
  }
  .pre-title-text-2 {
    @apply font-overpass font-bold text-[13px] md:text-base !leading-relaxed;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.48px;
  }
  .small-title-text {
    @apply font-overpass font-bold text-lg md:text-[22px];
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: 1.1px;
  }
  .tab-text {
    @apply text-xl font-overpass font-black !leading-none;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.6px;
  }
  .body-text {
    @apply font-nunito text-base sm:text-lg xl:text-xl !leading-tight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.6px;
  }
  .small-body-semibold-text {
    @apply font-nunito text-base sm:text-lg font-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.51px;
  }
  .navigation-text {
    @apply font-overpass text-[17px] font-semibold;
    line-height: 1em;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.85px;
  }

  .windows .navigation-text {
    @apply lg:pt-1;
  }

  .small-body-text {
    @apply font-nunito font-normal text-base;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.51px;
  }
  .button-text {
    @apply font-overpass text-base font-black !leading-none;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.48px;
  }
  .footer-text {
    @apply font-overpass text-sm font-semibold !leading-snug;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.48px;
  }
  .small-button-text {
    @apply font-overpass font-black;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.33;
    letter-spacing: 0.45px;
  }
  .label-text {
    @apply font-overpass text-sm font-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 0.42px;
  }
  .slide-title-text {
    @apply font-overpass text-[32px] sm:text-[26px] font-extrabold !leading-tight;
    font-stretch: normal;
    font-style: normal;
  }
}